// 用户中心
import request from '../utils/request';

// 获取评论
export async function getComments(type, current) {
    try {
        return await request({
            url: `/publicComment/myComment?type=${type}&current=${current ? current :1}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
// 获取简历
export async function getCv() {
    try {
        return await request({
            url: `/referCandidate`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
// 保存简历
export async function saveCv(data) {
    try {
        return await request({
            url: `/referCandidate`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 获取用户中心 内推列表
export async function getAccessList(params) {
    try {
        return await request({
            url: `/referCandidate/myRefer`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}
// 获取邀请列表
export async function getReferInviteList(params) {
    try {
        return await request({
            url: `/referCandidate/invite`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}

// 拒绝邀请 
export async function refuseInvite(id) {
    try {
        return await request({
            url: `/referCandidate/refuse/${id}`,
            method: 'put',
        });
    } catch (error) {
        return {};
    }
}