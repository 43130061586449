<template>
<!-- 填写简历弹窗 -->
  <el-dialog :visible.sync="dialogVisible" width="800px" append-to-body>
    <el-form :model="form" ref="ruleForm" label-width="150px" :rules="rules">
      <el-row>
        <el-col :span="12">
          <el-form-item label="姓名" prop="name">
            {{ form.name }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱" prop="email">
            {{ form.email }}
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="所学专业" prop="specialty">
        {{ form.specialty && form.specialty.name }}
      </el-form-item>

      <el-form-item label="毕业院校" prop="school">
        <div
          class="relativeInput"
          v-for="(school, index) in form.school"
          :key="index"
        >
          {{ form.school[index].name }}/{{
            form.school[index].qualification == 0
              ? "本科"
              : form.school[index].qualification == 1
              ? "硕士"
              : "博士"
          }}
        </div>
      </el-form-item>

      <el-form-item label="曾经公司" prop="originalCompany">
        <div
          class="relativeInput"
          v-for="(company, index) in form.company"
          :key="index"
        >
          {{ company }}
        </div>
      </el-form-item>
      <el-form-item label="项目经验" prop="project">
        <div
          v-for="(project, index) in form.project"
          class="relativeInput"
          :key="index"
        >
          <div style="margin-bottom: 6px">{{ project.title }}</div>
          <div>{{ project.content }}</div>
        </div>
      </el-form-item>

      <el-row>
        <el-col :span="12">
          <el-form-item label="工作经验" prop="workExperience">
            {{ form.workExperience && form.workExperience.name }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="刷题量" prop="questions">
            {{ form.questions }}
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="面试岗位" prop="post">
            <el-select
              v-model="form.post"
              placeholder="请选择岗位"
              style="margin-bottom: 0"
              @change="$forceUpdate()"
            >
              <el-option label="码农SDE" :value="0"></el-option>
              <el-option label="前端FrontEndEng" :value="1"></el-option>
              <el-option label="移动端MobileEng" :value="2"></el-option>
              <el-option label="机器学习MachineLearningEng" :value="3"></el-option>
              <el-option label="数据科学DataScience" :value="4"></el-option>
              <el-option label="产品PM" :value="5"></el-option>
              <el-option label="管理Manager" :value="6"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="求职目标" prop="target">
            <el-select
              v-model="form.target"
              :disabled="type == 'interpolationListAccess'"
            >
              <el-option label="New Grad" :value="0"></el-option>
              <el-option label="Internship" :value="1"></el-option>
              <el-option label="合同工" :value="2"></el-option>
              <el-option label="跳槽" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        label="岗位ID链接"
        v-if="type == 'acceptInvite'"
        prop="postId"
      >
        <el-input
          v-model="form.postId"
          placeholder="请输入岗位ID链接"
        ></el-input>
      </el-form-item>
      <el-form-item label="简历" prop="resume">
        <el-upload
          ref="upload"
          :on-success="handlePDFSuccess"
          :limit="1"
          :before-upload="beforeUpload"
          :on-remove="removePDFFile"
          :action="uploadUrl"
          class="upload-demo"
          accept=".pdf,.PDF"
          style="display: inline-block; width: 100%"
          :data="{ isPublic: false }"
          :headers="{ 'X-Access-Token': token }"
        >
          <el-button icon="el-icon-paperclip">选择简历</el-button>
          <div class="el-upload__tip" slot="tip">
            支持pdf格式文件，文件大小不超过10M
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="第三人称简介" prop="briefIntroduction">
        <el-input
          v-model="form.briefIntroduction"
          type="textarea"
          placeholder="第三人称个人简介，内推时需要用到"
          @input="$forceUpdate()"
        ></el-input>
      </el-form-item>
      <el-form-item label="自我介绍" prop="selfIntroduction">
        <el-input
          v-model="form.selfIntroduction"
          type="textarea"
          placeholder="增加内推概率，让内推人对你更了解"
          @input="$forceUpdate()"
        ></el-input>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <el-button type="primary" @click="handleSave">保存信息</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getCv, saveCv } from "@/service/userCenter";
import { accessInterpolation, acceptInvite } from "@/service/interpolation";
export default {
  name: "cvDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    accessType: {
      type: [Number, String],
      default: "",
    },
    type: {
      type: String,
      default: "interpolationListAccess",
    },
    companyId: {
      type: [String, Number],
    },
    inviteId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      form: {},
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      rules: {
        post: [
          {
            required: true,
            message: "请选择面试岗位",
            trigger: "change",
          },
        ],
        resume: [
          {
            required: true,
            message: "请上传简历",
            trigger: "change",
          },
        ],
        postId: [
          {
            required: () => {
              return this.type == "acceptInvite";
            },
            message: "请填写岗位链接ID",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      }
      return isLt10M;
    },
    handlePDFSuccess(res, file) {
      this.form.resume = res.result.objectKey;
    },
    removePDFFile() {
      this.form.resume = "";
    },
    handleLCSuccess(res) {
      this.$message.success("上传成功！");
      this.form.lcPrintScreen = res.result.objectKey;
    },
    getCv() {
      getCv().then((res) => {
        if (res.success) {
          this.form = res.result;
          if (this.accessType !== "") {
            this.form.target = this.accessType;
          } else {
            this.form.target = res.result.target ? res.result.target.value : "";
          }
          this.form.post = res.result.post ? res.result.post.value : "";
        }
      });
    },
    handleSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.type == "interpolationListAccess") {
            accessInterpolation({
              company: this.companyId,
              resume: this.form.resume,
              post: this.form.post,
              target: this.form.target,
              selfIntroduction: this.form.selfIntroduction,
              briefIntroduction: this.form.briefIntroduction,
            }).then((res) => {
              if (res.success) {
                this.$message.success("提交成功！");
                this.$emit("reloadList");
                this.dialogVisible = false;
              }
            });
          } else if (this.type == "acceptInvite") {
            acceptInvite(
              {
                resume: this.form.resume,
                post: this.form.post,
                postId: this.form.postId,
                target: this.form.target,
                selfIntroduction: this.form.selfIntroduction,
                briefIntroduction: this.form.briefIntroduction,
              },
              this.inviteId
            ).then((res) => {
              if (res.success) {
                this.$message.success("接受邀约成功！");
                this.$emit("reloadList");
                this.dialogVisible = false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getCv();
      }
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeDialogVisible", val);
      },
    },
  },
};
</script>