<template>
  <!-- 内推主页 -->
  <div>
    <div class="banner">
      <div class="contentText">
        <div class="title">北美公司汇总 免费内推招聘</div>
        <div class="subtitle">
          世界级互联网公司，求职链接薪资一应俱全，各大公司同胞内推
        </div>
      </div>
      <div class="searchArea">
        <el-input
          v-model="searchKey"
          style="width: 300px"
          placeholder="搜索公司"
          @input="searchLodash"
          prefix-icon="el-icon-search"
        ></el-input>
        <el-button type="primary" v-if="isRegisterd" @click="goCenter"
          >我是内推人</el-button
        >
        <el-button type="primary" v-else @click="goRegister"
          >申请内推人</el-button
        >
      </div>
    </div>
    <div class="tableArea">
      <el-table
        :data="tableData"
        style="
          width: 1200px;
          margin: 0 auto;
          margin-top: -32px;
          min-height: 500px;
          border-radius: 4px;
        "
        :key="itemKey"
      >
        <el-table-column>
          <template slot="header">
            <div>公司名称</div>
            <div>公司地点</div>
          </template>
          <template slot-scope="scope">
            <div class="companyContainer">
              <img class="companyLogo" :src="scope.row.logo" alt="" />
              <div>
                <div class="companyName" :title="scope.row.name">
                  {{ scope.row.name }}
                </div>
                <div class="companyDes">
                  {{ scope.row.address }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div>
              <div class="orange">New Grad Salary /y</div>
              <div class="orange">New Grad Link</div>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="orange"
              v-if="
                scope.row.careerFair &&
                scope.row.careerFair.details['1'] &&
                scope.row.careerFair.details['1'].annualSalary
              "
            >
              {{ !scope.row.isDomestic ? "$" : "￥"
              }}{{ scope.row.careerFair.details["1"].annualSalary }} /y
            </div>
            <div
              class="orange"
              v-else
              style="
                cursor: pointer;
                text-decoration: underline;
                display: inline-block;
              "
              @click="provideResource(scope.row)"
            >
              提供信息
            </div>
            <div class="newGrad" style="cursor: pointer">
              <div
                class="applyLink"
                v-if="
                  scope.row.careerFair &&
                  scope.row.careerFair.details['1'] &&
                  scope.row.careerFair.details['1'].link
                "
                @click="jumpLink(scope.row.careerFair.details['1'].link)"
              >
                Apply&nbsp;→
              </div>
              <div
                class="notOpenLink"
                v-else
                @click="jumpLink(scope.row.careerFair.link)"
              >
                Unknown
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div>
              <div class="blue">Internship Salary /m</div>
              <div class="blue">Internship Link</div>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="blue"
              v-if="
                scope.row.careerFair &&
                scope.row.careerFair.details['0'] &&
                scope.row.careerFair.details['0'].monthlySalary
              "
            >
              {{ !scope.row.isDomestic ? "$" : "￥"
              }}{{ scope.row.careerFair.details["0"].monthlySalary }} /m
            </div>
            <div
              class="blue"
              v-else
              style="
                cursor: pointer;
                text-decoration: underline;
                display: inline-block;
              "
              @click="provideResource(scope.row)"
            >
              提供信息
            </div>
            <div class="internship">
              <div
                class="applyLink"
                v-if="
                  scope.row.careerFair &&
                  scope.row.careerFair.details['0'] &&
                  scope.row.careerFair.details['0'].link
                "
                @click="jumpLink(scope.row.careerFair.details['0'].link)"
              >
                Apply&nbsp;→
              </div>
              <div
                class="notOpenLink"
                v-else
                @click="jumpLink(scope.row.careerFair.link)"
              >
                Unknown
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div>
              <div class="green">在职跳槽Link</div>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="applyLink"
              v-if="
                scope.row.careerFair &&
                scope.row.careerFair.details['2'] &&
                scope.row.careerFair.details['2'].link
              "
              @click="jumpLink(scope.row.careerFair.details['2'].link)"
            >
              Apply&nbsp;→
            </div>
            <div
              class="notOpenLink"
              v-else
              @click="jumpLink(scope.row.careerFair.link)"
            >
              Unknown
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="careerFair.jobFeelings">
          <template slot="header">申请状态</template>
          <template slot-scope="scope">
            <div
              class="applyLink access"
              v-if="scope.row.status && scope.row.status.value == 0"
              @click="openProvideDialog(scope.row)"
            >
              申请
            </div>
            <div
              class="accessing"
              v-else-if="scope.row.status && scope.row.status.value == 1"
            >
              申请中
            </div>
            <div
              class="endAccess"
              v-else-if="scope.row.status && scope.row.status.value == 2"
            >
              申请结束
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="padding: 30px; margin-top: 12px; text-align: center"
        prev-text="上一页"
        next-text="下一页"
        :current-page.sync="currentPage"
        :total="total"
        layout="prev, pager, next"
        :page-size="50"
        @current-change="getResources"
      >
      </el-pagination>
      <el-dialog :visible.sync="provideDialogVisible">
        <div class="provideContainer">
          <el-button class="provideBtn" @click="access(0)"
            >申请New Grad</el-button
          >
          <el-button class="provideBtn" @click="access(1)"
            >申请Internship</el-button
          >
          <el-button class="provideBtn" @click="access(3)"
            >申请在职跳槽</el-button
          >
        </div>
      </el-dialog>
      <el-dialog :visible.sync="successVisible">
        <div class="provideContainer">
          <div style="text-align: center">
            <img src="@/assets/img/sh-success.png" alt="" />
            <div class="success-text">已成功提交申请!</div>
            <el-button
              type="primary"
              @click="
                provideDialogVisible = false;
                isAccessSuccess = false;
              "
              >完成</el-button
            >
          </div>
        </div>
      </el-dialog>
      <CvDialog
        :visible="cvDialogVisible"
        @changeDialogVisible="(val) => (cvDialogVisible = val)"
        :accessType="accessType"
        type="interpolationListAccess"
        :companyId="currentCompanyId"
        @reloadList="getResources(currentPage)"
      ></CvDialog>
    </div>
  </div>
</template>
<script>
import { getInterpolationList } from "@/service/interpolation";
import { getReferRegisterDetail } from "@/service/refer";
import CvDialog from "./cvDialog";
import _ from "lodash";
import { mapState } from "vuex";
export default {
  name: "interpolation",
  components: { CvDialog },
  data() {
    return {
      searchKey: "",
      currentPage: 1,
      total: 0,
      tableData: [],
      provideDialogVisible: false,
      pageSize: 50,
      order: "",
      orderType: null,
      isAccessSuccess: false,
      hasResume: false,
      itemKey: Math.random(),
      isRegisterd: false,
      currentItem: {},
      accessType: "",
      successVisible: false,
      cvDialogVisible: false,
      currentCompanyId: "",
    };
  },
  methods: {
    getResources(page) {
      if (page) {
        if (!this.userInfo || !this.userInfo.id) {
          this.$router.push("/registerform");
        }
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
      getInterpolationList({
        current: page ? page : 1,
        name: this.searchKey,
        size: 50,
      }).then((res) => {
        if (res.success) {
          window.scrollTo(0, 0);
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    jumpLink(url) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
      }
      window.open(url);
    },
    access(type) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.accessType = type;
      this.provideDialogVisible = false;
      this.cvDialogVisible = true;
    },
    searchLodash: _.debounce(function () {
      this.getResources(1);
    }, 1000),
    provideResource(item) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
      }
      this.$router.push(
        `/provideResource?type=editResource&companyId=${item.id}&companyName=${item.name}`
      );
    },
    goCenter() {
      this.$router.push("/referCenter/list");
    },
    goRegister() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.$router.push("/referRegister");
    },
    openProvideDialog(item) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.currentCompanyId = item.id;
      this.provideDialogVisible = true;
    },
  },
  mounted() {
    this.getResources();
    getReferRegisterDetail().then((res) => {
      if (res.success) {
        if (res.result.audit && res.result.audit.value == 1) {
          this.isRegisterd = true;
        }
      }
    });
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style scoped lang="scss">
.banner {
  width: 100%;
  background: url(../../assets/img/interpolation.png) no-repeat;
  background-size: 100% 100%;
  height: 320px;
  .contentText {
    text-align: center;
    color: #fff;
    padding-top: 108px;
    .title {
      font-size: 28px;
    }
    .subtitle {
      font-size: 14px;
    }
  }
  .searchArea {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 52px;
  }
}
.orange {
  color: rgba(250, 100, 0, 1);
  font-size: 14px;
}
.blue {
  color: rgba(10, 122, 255, 1);
  font-size: 14px;
}
.green {
  color: rgba(36, 199, 108, 1);
  font-size: 14px;
}
.companyContainer {
  display: flex;
  padding: 20px 0;
  .companyLogo {
    width: 30px;
    height: 30px;
    margin-right: 12px;
    vertical-align: middle;
    transform: translateY(6px);
  }
  .companyName {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
  }
  .companyDes {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.8);
    line-height: 18px;
  }
}
.applyLink {
  padding: 4px 8px;
  display: inline-block;
  background: rgba(10, 122, 255, 0.3);
  border-radius: 12px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #0a7aff;
  line-height: 18px;
  cursor: pointer;
  &.access {
    padding: 4px 20px;
  }
}
.notOpenLink {
  padding: 4px 8px;
  display: inline-block;
  background: rgba(118, 118, 128, 0.24);
  border-radius: 12px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #757575;
  line-height: 18px;
  cursor: pointer;
}
::v-deep .el-table th {
  background: #f4f4f4;
}
.loginTip {
  text-align: center;
  padding: 30px;
}
.provideBtn {
  width: 80%;
  margin: 0 10%;
}
.provideContainer .el-button {
  margin-bottom: 24px;
  &:hover {
    background: #0a7aff;
    color: #fff;
  }
}
.contentContainer {
  padding: 24px;
  .comment {
    width: 100%;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    text-decoration: underline;
    margin-bottom: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.el-radio.is-bordered {
  ::v-deep .el-radio__input {
    display: none;
  }
  ::v-deep .el-radio__label {
    padding-left: 0;
  }
}
::v-deep .el-radio--small.is-bordered {
  padding: 8px 10px;
  border-radius: 16px;
}
::v-deep .el-radio {
  margin-right: 0px;
}
.submitBtn {
  width: 160px;
  border-radius: 16px;
  margin: 0 auto;
}
::v-deep .el-table th {
  padding: 30px;
}
::v-deep .el-table td {
  padding: 0 30px;
}
::v-deep .el-dialog {
  border-radius: 6px;
  width: 30%;
}
.jobFeelings {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
::v-deep .writeSug .el-dialog__body {
  padding-bottom: 0px;
}
.active {
  color: #0a7aff;
}
.success-text {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #757575;
  line-height: 21px;
  margin-top: 16px;
  margin-bottom: 24px;
}
.accessing {
  border-radius: 12px;
  display: inline-block;
  border: 1px solid #0a7aff;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #0a7aff;
  line-height: 18px;
  padding: 4px 16px;
}
.endAccess {
  border-radius: 12px;
  display: inline-block;
  border: 1px solid #999;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #999;
  line-height: 18px;
  padding: 4px 16px;
}
</style>